import React, { Fragment } from "react";

export default ({ label, value, onChange }) => (
  <Fragment>
    <label id="radiobutton-label">
      <input
        type="radio"
        checked={value}
        onChange={onChange}
        id="radiobutton-input"
      />
      {label}
    </label>
  </Fragment>
);
