import React, { Fragment } from 'react';

const NotFound = () => {
  return (
    <Fragment>
      <h1 className="x-large text-primary" id="dashborad-notfound-1">
        <i
          className="fas fa-exclamation-triangle"
          id="dashborad-notfound-icon"
        />{" "}
        Page Not Found
      </h1>
      <p className="large" id="dashborad-notfound-text">
        Sorry, this page does not exist
      </p>
    </Fragment>
  );
};

export default NotFound;
