import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  // console.clear();

  return (
    <Fragment>
      <h1 className="large text-primary" id="loginpage-h1">
        Sign In
      </h1>
      <p className="lead" id="loginpage-p1">
        <i className="fas fa-user" id="loginpage-singin-icon" /> Sign Into Your
        Account
      </p>
      <form className="form" onSubmit={(e) => onSubmit(e)} id="loginpage-form">
        <div className="form-group" id="loginpage-form-email-container">
          <input
            id="loginpage-input-email"
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group" id="loginpage-form-pw-container">
          <input
            id="loginpage-form-pw-input"
            type="password"
            placeholder="Password"
            name="password"
            minLength="6"
            value={password}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          value="Login"
          id="loginpage-form-btn"
        />
      </form>
      <p className="my-1" id="loginpage-form-warn-text">
        Don't have an account? <Link to="/register">Sign Up</Link>
      </p>
      <span id="loginpage-form-help-text">
        <Link to="/auth/reset-password">Forgot your password?</Link>
      </span>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
