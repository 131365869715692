import React from "react";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation() || "";
  const showFooter = ["/", "/login", "/register"].includes(location.pathname);
  return showFooter ? (
    <footer className="footer bg-dark" id="footer-container">
      <small id="footer-text">
        Copyright &copy; {new Date().getFullYear()} DevBook
      </small>
    </footer>
  ) : null;
};

export default Footer;
