import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addEducation } from '../../actions/profile';

const AddEducation = ({ addEducation, history }) => {
  const [formData, setFormData] = useState({
    school: '',
    degree: '',
    fieldofstudy: '',
    from: '',
    to: '',
    current: false,
    description: '',
  });

  const [toDateDisabled, toggleDisabled] = useState(false);

  const { school, degree, fieldofstudy, from, to, current, description } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <h1 className="large text-primary" id="addecutaion-heading">
        Add Your Education
      </h1>
      <p className="lead" id="addecutaion-text">
        <i id="addecutaion-icon" className="fas fa-code-branch" /> Add any
        school or bootcamp that you have attended
      </p>
      <small>* = required field</small>
      <form
        id="addecutaion-form"
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          addEducation(formData, history);
        }}
      >
        <div id="addecutaion-form-addschool" className="form-group">
          <input
            id="addecutaion-form-addschool-btn"
            type="text"
            placeholder="* School or Bootcamp"
            name="school"
            value={school}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div id="addecutaion-form-adddegree" className="form-group">
          <input
            id="addecutaion-form-adddegree-btn"
            type="text"
            placeholder="* Degree or Certificate"
            name="degree"
            value={degree}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div id="addecutaion-form-addfield" className="form-group">
          <input
            id="addecutaion-form-addfield-btn"
            type="text"
            placeholder="Field of Study"
            name="fieldofstudy"
            value={fieldofstudy}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div id="addecutaion-form-adddate" className="form-group">
          <h4>From Date</h4>
          <input
            id="addecutaion-form-adddate-btn"
            type="date"
            name="from"
            value={from}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div id="addecutaion-form-addstatus" className="form-group">
          <p>
            <input
              id="addecutaion-form-addstatus-btn"
              type="checkbox"
              name="current"
              checked={current}
              value={current}
              onChange={() => {
                setFormData({ ...formData, current: !current });
                toggleDisabled(!toDateDisabled);
              }}
            />{" "}
            Current School
          </p>
        </div>
        <div id="addecutaion-form-adddatestatus" className="form-group">
          <h4 d="addecutaion-form-adddatestatus-heading">To Date</h4>
          <input
            d="addecutaion-form-adddatestatus-btn"
            type="date"
            name="to"
            value={to}
            onChange={(e) => onChange(e)}
            disabled={toDateDisabled ? "disabled" : ""}
          />
        </div>
        <div d="addecutaion-form-adddescription" className="form-group">
          <textarea
            d="addecutaion-form-adddatestatus-text"
            name="description"
            cols="30"
            rows="5"
            placeholder="Program Description"
            value={description}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input
          d="addecutaion-form-btn"
          type="submit"
          className="btn btn-primary my-1"
        />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

AddEducation.propTypes = {
  addEducation: PropTypes.func.isRequired,
};

export default connect(
  null,
  { addEducation }
)(withRouter(AddEducation));
