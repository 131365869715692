import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { deleteComment } from '../../actions/post';

const CommentItem = ({
  postId,
  comment: { id, text, name, avatar, user, date },
  auth,
  deleteComment,
}) => (
  <div className="post bg-white p-1 my-1" id="comment-container">
    <div id="comment-commentor-container">
      <Link to={`/profile/${user}`}>
        <img
          className="round-img"
          src={avatar}
          alt=""
          id="comment-commentor-container-image"
        />
        <h4 id="comment-commentor-name">{name}</h4>
      </Link>
    </div>
    <div>
      <p className="my-1" id="comment-comment-text">
        {text}
      </p>
      <p className="post-date" id="comment-comment-date">
        Posted on <Moment format="YYYY/MM/DD">{date}</Moment>
      </p>
      {!auth.loading && user.id === auth.user.id && (
        <button
          id="comment-comment-delete-btn"
          onClick={() => deleteComment(postId, id)}
          type="button"
          className="btn btn-danger"
        >
          <i className="fas fa-times" id="comment-comment-delete-btn-icon" />
        </button>
      )}
    </div>
  </div>
);

CommentItem.propTypes = {
  postId: PropTypes.number.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { deleteComment }
)(CommentItem);
