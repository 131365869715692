import React from "react";
import PropTypes from "prop-types";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const ProfileTwitter = ({ screenName }) => {
  return (
    <div className="centerContent" id="profile-twitter-main-container">
      <div
        className="selfCenter spaceBetween"
        id="profile-twitter-sub-container"
      >
        <TwitterTimelineEmbed
          onLoad={function noRefCheck() {}}
          options={{
            height: 600,
          }}
          screenName={screenName}
          sourceType="likes"
        />
      </div>
    </div>
  );
};

ProfileTwitter.propTypes = {
  screenName: PropTypes.string.isRequired,
};

export default ProfileTwitter;
