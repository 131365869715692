import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../actions/post';

const CommentForm = ({ postId, addComment }) => {
  const [text, setText] = useState('');

  return (
    <div className="post-form" id="post-from-container">
      <div className="bg-primary p" id="post-from-text-container">
        <h3 id="post-from-container-text">Leave a Comment</h3>
      </div>
      <form
        id="post-from"
        className="form my-1"
        onSubmit={(e) => {
          e.preventDefault();
          addComment(postId, { text });
          setText("");
        }}
      >
        <textarea
          id="post-from-textarea"
          name="text"
          cols="30"
          rows="5"
          placeholder="Comment"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        <input
          id="post-from-btn"
          type="submit"
          className="btn btn-dark my-1"
          value="Submit"
        />
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
};

export default connect(
  null,
  { addComment }
)(CommentForm);
