import React from 'react';
import PropTypes from 'prop-types';

const ProfileTop = ({
  profile: {
    status,
    company,
    location,
    website,
    social,
    user: { name, avatar },
  },
}) => {
  const http = 'http://';
  const websitePath = http + website;
  return (
    <div className="profile-top bg-primary p-2" id="profile-top-cantainer">
      <img
        className="round-img my-1"
        src={avatar}
        alt=""
        id="profile-top-image"
      />
      <h1 className="large" id="profile-top-heading">
        {name}
      </h1>
      <p className="lead" id="profile-top-details">
        {status} {company && <span> at {company}</span>}
      </p>
      <p id="profile-top-details-location">
        {location && <span>{location}</span>}
      </p>
      <div className="icons my-1" id="profile-top-website-container">
        {website && (
          <a
            href={websitePath}
            id="profile-top-website-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-globe fa-2x" />
          </a>
        )}
        {social && social.twitter && (
          <a
            id="profile-top-twitter-anchor"
            href={social.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i id="profile-top-twitter-icon" className="fab fa-twitter fa-2x" />
          </a>
        )}
        {social && social.facebook && (
          <a
            href={social.facebook}
            id="profile-top-facebook-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id="profile-top-facebook-icon"
              className="fab fa-facebook fa-2x"
            />
          </a>
        )}
        {social && social.linkedin && (
          <a
            href={social.linkedin}
            id="profile-top-linkedin-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id="profile-top-linkedin-icon"
              className="fab fa-linkedin fa-2x"
            />
          </a>
        )}
        {social && social.youtube && (
          <a
            href={social.youtube}
            id="profile-top-youtube-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i id="profile-top-youtube-icon" className="fab fa-youtube fa-2x" />
          </a>
        )}
        {social && social.instagram && (
          <a
            href={social.instagram}
            id="profile-top-instagram-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id="profile-top-isntagram-icon"
              className="fab fa-instagram fa-2x"
            />
          </a>
        )}
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileTop;
