import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentPopup = () => {
  return (
    <div className="popup" id="cookies-container">
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName3"
        expires={999}
        overlay
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
};

export default CookieConsentPopup;
