import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getGithubRepos } from '../../actions/profile';

const ProfileGithub = ({ username, getGithubRepos, repos }) => {
  useEffect(() => {
    getGithubRepos(username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGithubRepos]);

  return (
    <div className="profile-github" id="profile-github-main-container">
      <h2 className="text-primary my-1" id="profile-github-heading">
        Github Repos
      </h2>
      {repos === null ? (
        <Spinner />
      ) : (
        repos.map((repo) => (
          <div
            key={repo.id}
            className="repo bg-white p-1 my-1"
            id="profile-github-repo-container"
          >
            <div id="profile-github-repo-sub-container">
              <h4 id="profile-github-repo-sub-heading">
                <a
                  id="profile-github-repo-sub-anchor"
                  href={repo.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {repo.name}
                </a>
              </h4>
              <p id="profile-github-repo-description">{repo.description}</p>
            </div>
            <div id="profile-github-repo-badge-container">
              <ul>
                <li
                  id="profile-github-repo-badge-stars"
                  className="badge badge-primary"
                >
                  Stars: {repo.stargazers_count}
                </li>
                <li
                  id="profile-github-repo-badge-watchers"
                  className="badge badge-dark"
                >
                  Watchers: {repo.watchers_count}
                </li>
                <li
                  id="profile-github-repo-badge-forks"
                  className="badge badge-light"
                >
                  Forks: {repo.forks_count}
                </li>
              </ul>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

ProfileGithub.propTypes = {
  getGithubRepos: PropTypes.func.isRequired,
  repos: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  repos: state.profile.repos,
});

export default connect(
  mapStateToProps,
  { getGithubRepos }
)(ProfileGithub);
