import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import logo from "../../img/devbook.png";

const SubMenu = ({ logout }) => (
  <ul className="nav__submenu" id="navbar-menu-list">
    <li className="nav__submenu-item " id="navbar-menu-list-item1">
      <Link to="/dashboard">
        <i className="fas fa-user" id="navbar-menu-list-item1-icon" />{" "}
        <span className="hide-sm" id="navbar-menu-list-item1-dashboard">
          Dashboard
        </span>
      </Link>
    </li>
    <li className="nav__submenu-item " id="navbar-menu-list-item2">
      <Link to="/posts">
        <i className="fab fa-usps" id="navbar-menu-list-item2-icon" />{" "}
        <span className="hide-sm" id="navbar-menu-list-item1-myposts">
          My Posts
        </span>
        {/* TODO: implement my posts route */}
      </Link>
    </li>
    <li className="nav__submenu-item " id="navbar-menu-list-item3">
      <a onClick={logout} href="/login" id="navbar-menu-list-item3-anchor">
        <i className="fas fa-sign-out-alt" id="navbar-menu-list-item3-icon" />
        <span className="hide-sm" id="navbar-menu-list-item3-text">
          Logout
        </span>
      </a>
    </li>
  </ul>
);

SubMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

const CSubMenu = connect(null, { logout })(SubMenu);

const AuthLinks = () => (
  <ul className="nav__menu" id="navbar-menu-list2">
    <li className="nav__menu-item" id="navbar-menu-list2-item1">
      <Link to="/profiles">Developers</Link>
    </li>
    <li className="nav__menu-item" id="navbar-menu-list2-item2">
      <Link to="/posts">All Posts</Link>
    </li>
    <li className="nav__menu-item" id="navbar-menu-list2-item3">
      <Link to="/dashboard">
        <i className="fas fa-user" id="navbar-menu-list2-item3-icon" />{" "}
        <span className="hide-sm" id="navbar-menu-list2-item3-text">
          My Account
        </span>
      </Link>
      <CSubMenu className="nav__submenu" />
    </li>
  </ul>
);

const SubMenuGuest = () => (
  <ul className="nav__submenu" id="navbar-submenu-list">
    <li className="nav__submenu-item " id="navbar-submenu-list-item">
      <Link to="/register">Register</Link>
    </li>
  </ul>
);

const GuestLinks = () => (
  <ul className="nav__menu" id="navbar-submenu-list2">
    <li className="nav__menu-item" id="navbar-submenu-list2-item1">
      <Link to="/profiles">Developers</Link>
    </li>
    <li className="nav__menu-item" id="navbar-submenu-list2-item2">
      <Link to="/login">Login</Link>
      <SubMenuGuest className="nav__submenu" />
    </li>
  </ul>
);

const Navbar = ({ auth: { isAuthenticated, loading } }) => {
  return (
    <nav className="navbar bg-dark" id="navbar-menu">
      <Link to="/">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={logo} alt="logo" width={40} height={40} />
          <h1 id="navbar-menu-h1">DevBook</h1>
        </div>
      </Link>
      {!loading && (
        <Fragment>{isAuthenticated ? <AuthLinks /> : <GuestLinks />}</Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Navbar);
