import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ProfileTop from "./ProfileTop";
import ProfileAbout from "./ProfileAbout";
import ProfileExperience from "./ProfileExperience";
import ProfileEducation from "./ProfileEducation";
import ProfileGithub from "./ProfileGithub";
import { getProfileById } from "../../actions/profile";
import ProfileTwitter from "./ProfileTwitter";

const Profile = ({
  getProfileById,
  profile: { profile, loading },
  auth,
  match,
}) => {
  useEffect(() => {
    getProfileById(match.params.id);
  }, [getProfileById, match.params.id]);

  return (
    <Fragment>
      {profile === null || loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Link to="/profiles" className="btn btn-light">
            Back To Profiles
          </Link>
          {auth.isAuthenticated &&
            auth.loading === false &&
            auth.user.id === profile.user.id && (
              <Link to="/edit-profile" className="btn btn-dark">
                Edit Profile
              </Link>
            )}
          <div className="profile-grid my-1" id="profile-main-container">
            <ProfileTop profile={profile} />
            <ProfileAbout profile={profile} />
            <div
              className="profile-exp bg-white p-2"
              id="profile-experience-container"
            >
              <h2 className="text-primary" id="profile-experience-text">
                Experience
              </h2>
              {profile.experience?.length > 0 ? (
                <Fragment>
                  {profile.experience.map((experience) => (
                    <ProfileExperience
                      key={experience.id}
                      experience={experience}
                    />
                  ))}
                </Fragment>
              ) : (
                <h4 id="profile-experience-noexperience">
                  No experience credentials
                </h4>
              )}
            </div>

            <div
              className="profile-edu bg-white p-2"
              id="profile-experience-container"
            >
              <h2 className="text-primary" id="profile-experience-heading">
                Education
              </h2>
              {profile.education?.length > 0 ? (
                <Fragment>
                  {profile.education.map((education) => (
                    <ProfileEducation
                      key={education.id}
                      education={education}
                    />
                  ))}
                </Fragment>
              ) : (
                <h4 id="profile-experience-noexperience">
                  No education credentials
                </h4>
              )}
            </div>

            {profile.githubusername && (
              <ProfileGithub username={profile.githubusername} />
            )}
          </div>
          {profile.social?.twitter && (
            <ProfileTwitter screenName={profile.social.twitter} />
          )}
          <div
            className="profile-about bg-light p-2"
            id="profile-recommended-video-container"
          >
            <h2 className="text-primary" id="profile-recommended-video-heading">
              Recommended Video
            </h2>
            <iframe
              id="profile-recommended-video-iframe"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iBUJVy8phqw"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById })(Profile);
