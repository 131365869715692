import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const ProfileEducation = ({
  education: { school, degree, fieldofstudy, current, to, from, description },
}) => (
  <div id="profile-education-main-container">
    <h3 id="profile-education-school" className="text-dark">
      {school}
    </h3>
    <p id="profile-education-school-date">
      <Moment format="YYYY/MM/DD">{from}</Moment> -{" "}
      {!to ? " Now" : <Moment format="YYYY/MM/DD">{to}</Moment>}
    </p>
    <p id="profile-education-school-degree">
      <strong>Degree: </strong> {degree}
    </p>
    <p id="profile-education-school-fieldofstudy">
      <strong>Field Of Study: </strong> {fieldofstudy}
    </p>
    <p id="profile-education-description">
      <strong>Description: </strong> {description}
    </p>
  </div>
);

ProfileEducation.propTypes = {
  education: PropTypes.array.isRequired,
};

export default ProfileEducation;
