import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

const DashboardActions = () => {
  return (
    <div className="dash-buttons mb-3" id="dashboard-edit-container">
      <Link
        to="/edit-profile"
        className="btn btn-light"
        data-tip
        data-for="edit-profile-btn"
      >
        <i
          className="fas fa-user-circle text-primary"
          id="dashboard-edit-icon"
        />
        Edit Profile
        <ReactTooltip id="edit-profile-btn" place="top" effect="solid">
          You may edit your profile by clicking this button
        </ReactTooltip>
      </Link>

      <Link
        to="/add-experience"
        className="btn btn-light"
        data-tip
        data-for="edit-experience-btn"
      >
        <i
          className="fab fa-black-tie text-primary"
          id="dashboard-addexperience-icon"
        />{" "}
        Add Experience
        <ReactTooltip id="edit-experience-btn" place="bottom" effect="solid">
          You may edit your experience by clicking this button
        </ReactTooltip>
      </Link>

      <Link
        to="/add-education"
        className="btn btn-light"
        data-tip
        data-for="edit-education-btn"
      >
        <i
          className="fas fa-graduation-cap text-primary"
          id="dashboard-addeducation-icon"
        />{" "}
        Add Education
        <ReactTooltip id="edit-education-btn" place="right" effect="solid">
          You may edit your education by clicking this button
        </ReactTooltip>
      </Link>
    </div>
  );
};

export default DashboardActions;
