import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createHash, passwordResetClear } from '../../actions/auth';
import PropTypes from 'prop-types';

const ResetPasswordPage = ({ isPasswordReset, createHash, passwordResetClear }) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  useEffect(() => {
    if (isPasswordReset) {
      setFormData({ ...formData, email: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormData]);

  const clearPasswordReset = e => {
    e.preventDefault();
    passwordResetClear();
  };

  const handleEmailChange = e => {
    setFormData({ ...formData, email: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    createHash(email);
  };

  return (
    <Fragment>
      {isPasswordReset ? (
        <Fragment>
          <div
            className="row justify-content-center"
            id="resetpwpage-reset-container"
          >
            <div
              className="col-10 col-sm-7 col-md-5 col-lg-4"
              id="resetpwpage-reset-subcontainer"
            >
              <p id="resetpwpage-reset-subcontainer-p1">
                An email has been sent to email address you provided containing
                a link to reset your password. Please click on that link to
                proceed with setting a new password.
              </p>
              <br />

              <p id="resetpwpage-reset-subcontainer-p2">
                And please check your spam box.
              </p>
              <p id="resetpwpage-reset-subcontainer-p3">
                <a
                  href="/auth/reset-password"
                  id="resetpwpage-reset-subcontainer-resentlink"
                  onClick={() => clearPasswordReset()}
                >
                  Re-send Email
                </a>
              </p>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <p id="resetpwpage-reset-text-p">
            If you want to reset your password, please enter your email here and
            a link will be sent to your email address to reset your password.
          </p>
          <form
            className="form"
            onSubmit={(e) => onSubmit(e)}
            id="resetpwpage-form"
          >
            <div className="form-group" id="resetpwpage-form-container">
              <input
                id="userEmail"
                name="email"
                onChange={(e) => handleEmailChange(e)}
                placeholder="Please enter your email here."
                required
                type="email"
                value={email}
              />
            </div>

            <p className="lead" id="resetpwpage-input-email-p">
              A valid email is required to reset your password
            </p>
            <input
              type="submit"
              className="btn btn-primary"
              value="Reset Password"
              id="resetpwpage-form-btn"
            />
          </form>
        </Fragment>
      )}
    </Fragment>
  );
};

ResetPasswordPage.propTypes = {
  isPasswordReset: PropTypes.bool,
};

const mapStateToProps = state => ({
  isPasswordReset: state.auth.isPasswordReset,
});

export default connect(
  mapStateToProps,
  { createHash, passwordResetClear }
)(ResetPasswordPage);
