import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register, socialRegister } from '../../actions/auth';
import PropTypes from 'prop-types';

const Register = ({ setAlert, register, isAuthenticated, socialRegister }) => {
  useEffect(() => {
    const token = new URL(window.location).searchParams.get('token');
    socialRegister(token);
  }, [socialRegister]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  });

  const { name, email, password, password2 } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match!', 'danger');
    } else {
      register({ name, email, password });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <h1 className="large text-primary" id="signuppage-h1">
        Sign Up
      </h1>
      <p className="lead" id="signuppage-p1">
        <i className="fas fa-user" id="signuppage-icon" /> Create Your Account
      </p>
      <form className="form" onSubmit={(e) => onSubmit(e)} id="signuppage-from">
        <div className="form-group" id="signuppage-form-name-container">
          <input
            id="signuppage-form-name-input"
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group" id="signuppage-form-email-container">
          <input
            id="signuppage-form-email-input"
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
          <small className="form-text" id="signuppage-form-gravatar-text">
            This site uses Gravatar so if you want a profile image, use a
            Gravatar email or login with your social account.
          </small>
        </div>
        <div className="form-group" id="signuppage-form-pw1-container">
          <input
            id="signuppage-form-pw1-input"
            type="password"
            placeholder="Password"
            name="password"
            minLength="6"
            value={password}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group" id="signuppage-form-pw2-container">
          <input
            id="signuppage-form-pw2-input"
            type="password"
            placeholder="Confirm Password"
            name="password2"
            minLength="6"
            value={password2}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          value="Register"
          id="signuppage-form-btn"
        />
      </form>
      <p className="my-1" id="signuppage-warn-p">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  socialRegister: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(
  mapStateToProps,
  // this helps us to reach setAlert within props
  { setAlert, register, socialRegister }
)(withRouter(Register));
