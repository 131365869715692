import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { addLike, addUnLike, deletePost } from "../../actions/post";

const baseURL = window.location.href;

const PostItem = ({
  addLike,
  addUnLike,
  deletePost,
  auth,
  post: { id, title, text, name, avatar, user, likes, unlikes, comments, date },
  showActions,
}) => (
  <div className="post bg-white p-1 my-1" id="post-item-container">
    <div id="post-writer">
      <Link to={`/profile/${user}`}>
        <img className="round-img" src={avatar} alt="" id="post-writer-image" />
        <h4 id="post-writer-name">{name}</h4>
      </Link>
    </div>
    <div id="post-item">
      <div>
        <h2
          className="my-1"
          id="post-item-heading"
          style={{ display: "inline", marginRight: "36px" }}
        >
          {title}
        </h2>
        {!auth.loading && user.id === auth.user.id && (
          <button
            id="post-item-edit-post-btn"
            onClick={() => null}
            type="button"
            className="btn"
            style={{ background: "#6fd6af" }}
          >
            <i
              className="fas fa-pen"
              id="post-item-edit-post-icon"
              style={{ color: "white" }}
            />
          </button>
        )}
      </div>
      <p className="my-1" id="post-item-text">
        {text}
      </p>
      <p className="post-date" id="post-item-date">
        Posted on <Moment format="YYYY/MM/DD">{date}</Moment>
      </p>

      {showActions && (
        <Fragment>
          <button
            id="post-item-add-like-btn"
            onClick={() => addLike(id)}
            type="button"
            className="btn btn-light"
          >
            <i className="fas fa-thumbs-up" id="post-item-add-like-icon" />{" "}
            <span id="post-item-add-like-no">
              {likes?.length > 0 && <span>{likes?.length}</span>}
            </span>
          </button>
          <button
            id="post-item-del-like-btn"
            onClick={() => addUnLike(id)}
            type="button"
            className="btn btn-light"
          >
            <i className="fas fa-thumbs-down" id="post-item-del-like-icon" />
            <span>{unlikes?.length > 0 && <span>{unlikes?.length}</span>}</span>
          </button>
          <Link to={`/posts/${id}`} className="btn btn-primary">
            Discussion
            {comments?.length > 0 && (
              <span className="comment-count" id="post-item-comment-count">
                {comments?.length}
              </span>
            )}
          </Link>
          {!auth.loading && user === auth.user.id && (
            <button
              id="post-item-del-post-btn"
              onClick={() => deletePost(id)}
              type="button"
              className="btn btn-danger"
            >
              <i className="fas fa-times" id="post-item-del-post-icon" />
            </button>
          )}
          <div className="social-parent" id="post-item-social-parent-container">
            <h4 className="shr-text shr-btn">Share on: </h4>
            <TwitterShareButton
              url={`${baseURL}/${id}`}
              title={title}
              className="shr-btn twitter"
            >
              <TwitterIcon size={38} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`${baseURL}/${id}`}
              className="shr-btn linkedin"
            >
              <LinkedinIcon size={38} round={true} />
            </LinkedinShareButton>
          </div>
        </Fragment>
      )}
    </div>
  </div>
);

PostItem.defaultProps = {
  showActions: true,
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  addUnLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, addUnLike, deletePost })(
  PostItem
);
