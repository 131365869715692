import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProfileAbout = ({
  profile: {
    bio,
    skills,
    user: { name },
    year,
  },
}) => (
  <div className="profile-about bg-light p-2" id="profile-about-container">
    {bio && (
      <Fragment>
        <h2 className="text-primary" id="profile-name-heading">
          {name.trim().split(" ")[0]}'s Bio
        </h2>
        <p id="profile-about-bio-text">{bio}</p>
        <div className="line" id="profile-about-line" />
      </Fragment>
    )}
    <h2 className="text-primary" id="profile-about-skills-heading">
      Skill Set
    </h2>
    <div className="skills" id="profile-about-skills-container">
      {skills.map((skill, index) => (
        <div key={index} className="p-1" id="profile-about-skills-items">
          <i className="fas fa-check" id="profile-about-skills-icon" /> {skill}
        </div>
      ))}
    </div>
    {year > 0 && (
      <Fragment>
        <h2 className="text-primary" id="profile-about-workexperience-heading">
          Work Experience
        </h2>
        <div
          className="work experience"
          id="profile-about-workexperience-text-container"
        >
          <p id="profile-about-workexperience-text">
            {year} year(s) work experience
          </p>
        </div>
      </Fragment>
    )}
  </div>
);

ProfileAbout.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileAbout;
