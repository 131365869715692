import React, { Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Education from "./Education";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile, loading },
  // isVerified,
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  // if (!isVerified) {
  //   return <Redirect to="/verification" />;
  // }

  // console.clear();
  console.log("🚀 ~ file: Dashboard.js ~ line 37 ~ profile", profile);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary" id="dashboard-h1">
        Dashboard
      </h1>
      <p className="lead" id="dashboard-p1">
        <i className="fas fa-user" id="dashboard-user-icon" /> Welcome{" "}
        {user && user.name}
      </p>
      {profile !== null ? (
        <Fragment>
          <DashboardActions />
          <Experience experience={profile?.experience ?? []} />
          <Education education={profile?.education ?? []} />

          <div className="my-2" id="dashboard-account-container">
            <button
              className="btn btn-danger"
              id="dashboard-account-delete-btn"
              onClick={() => deleteAccount()}
            >
              <i
                className="fas fa-user-minus"
                id="dashboard-account-delete-icon"
              />{" "}
              Delete My Account
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <p id="dashboard-new-account">
            You have not yet setup a profile, please add some info
          </p>
          <Link to="/create-profile" className="btn btn-primary my-1">
            Create Profile
          </Link>
        </Fragment>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  // isVerified: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  // isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
  Dashboard
);
