import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const ProfileExperience = ({
  experience: { company, title, location, current, to, from, description },
}) => (
  <div id="profile-experience-main-container">
    <h3 id="profile-experience-company" className="text-dark">
      {company}
    </h3>
    <p id="profile-experience-company-date">
      <Moment format="YYYY/MM/DD">{from}</Moment> -{" "}
      {!to ? " Now" : <Moment format="YYYY/MM/DD">{to}</Moment>}
    </p>
    <p id="profile-experience-company-position">
      <strong>Position: </strong> {title}
    </p>
    <p id="profile-experience-company-location">
      <strong>Location: </strong> {location}
    </p>
    <p id="profile-experience-company-description">
      <strong>Description: </strong> {description}
    </p>
  </div>
);

ProfileExperience.propTypes = {
  experience: PropTypes.array.isRequired,
};

export default ProfileExperience;
