import React, { Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register, verifyAccount } from "../../actions/auth";

const AfterConfirmation = ({ verifyAccount, match }) => {
  useEffect(() => {
    verifyAccount(match.params.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyAccount]);

  return (
    <Fragment>
      <h1 className="large text-primary" id="afterconfpage-h1">
        Verification
      </h1>
      <p className="lead" id="afterconfpage-verified-p">
        Your account is verified. You can go to your dashboard by clicking this{" "}
        <Link to="/dashboard">link</Link>.
      </p>

      <p className="my-1" id="afterconfpage-thank-p">
        Have a nice networking! Thank you.
      </p>
      <p className="my-1" id="afterconfpage-team-p">
        DevBook Team.
      </p>
    </Fragment>
  );
};

AfterConfirmation.propTypes = {
  verifyAccount: PropTypes.func.isRequired,
};

export default connect(null, { register, verifyAccount })(
  withRouter(AfterConfirmation)
);
