import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile } from "../../actions/profile";
import ReactTooltip from "react-tooltip";
import RadioButton from "../layout/RadioButton";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { parseTwoDigitYear } from "moment";

const CreateProfile = ({ createProfile, history }) => {
  const [formData, setFormData] = useState({
    company: "",
    year: 0,
    website: "",
    location: "",
    status: "",
    skills: "",
    githubusername: "",
    bio: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const {
    company,
    year,
    website,
    location,
    status,
    skills,
    githubusername,
    bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history);
  };

  const handleRadio = () => {
    toggleSocialInputs(!displaySocialInputs);
  };

  return (
    <Fragment>
      <h1 className="large text-primary" id="createprofile-heading">
        Create Your Profile
      </h1>
      <p className="lead" id="createprofile-intro-text">
        <i className="fas fa-user" id="createprofile-intro-icon" /> Let's get
        some information to make your profile stand out
      </p>
      <small>* = required field</small>
      <form
        id="createprofile-from"
        className="form"
        onSubmit={(e) => onSubmit(e)}
      >
        <div className="form-group" id="createprofile-from-status">
          <select
            name="status"
            value={status}
            onChange={(e) => onChange(e)}
            id="createprofile-from-status-select"
          >
            <option id="createprofile-from-status-option1" value="0">
              * Select Professional Status
            </option>
            <option id="createprofile-from-status-option2" value="QA Engineer">
              QA Engineer
            </option>
            <option
              id="createprofile-from-status-option3"
              value="Senior QA Engineer"
            >
              Senior QA Engineer
            </option>
            <option
              id="createprofile-from-status-option4"
              value="QA Manuel Tester"
            >
              QA Manuel Tester
            </option>
            <option
              id="createprofile-from-status-option5"
              value="QA Automation Tester"
            >
              QA Automation Tester
            </option>
            <option id="createprofile-from-status-option6" value="Developer">
              Developer
            </option>
            <option
              id="createprofile-from-status-option7"
              value="Junior Developer"
            >
              Junior Developer
            </option>
            <option
              id="createprofile-from-status-option8"
              value="Senior Developer"
            >
              Senior Developer
            </option>
            <option id="createprofile-from-status-option9" value="Manager">
              Manager
            </option>
            <option
              id="createprofile-from-status-option10"
              value="Student or Learning"
            >
              Student or Learning
            </option>
            <option id="createprofile-from-status-option11" value="Instructor">
              Instructor or Teacher
            </option>
            <option id="createprofile-from-status-option12" value="Intern">
              Intern
            </option>
            <option id="createprofile-from-status-option13" value="Other">
              Other
            </option>
          </select>
          <small
            id="createprofile-from-status-description"
            className="form-text"
          >
            Give us an idea of where you are at in your career
          </small>
        </div>
        {/* Slider Area Start*/}
        {/* Slider Area Start*/}
        <div id="createprofile-from-slider" className="slidecontainer">
          <Slider
            name="year"
            value={year}
            min={0}
            max={10}
            step={1}
            onChange={(val) => setFormData({ ...formData, year: val })}
          />
          <small
            id="createprofile-from-slider-description"
            className="form-text"
          >
            How many years of work experience you have?
          </small>
        </div>
        {/* Slider Area End*/}
        {/* Slider Area End*/}
        <div id="createprofile-from-company" className="form-group">
          <input
            id="createprofile-from-company-input"
            type="text"
            placeholder="Company"
            name="company"
            value={company}
            onChange={(e) => onChange(e)}
          />
          <small
            id="createprofile-from-company-description"
            className="form-text"
          >
            Could be your own company or one you work for
          </small>
        </div>
        <div id="createprofile-from-website" className="form-group">
          <input
            id="createprofile-from-website-input"
            type="text"
            placeholder="Website"
            name="website"
            value={website}
            onChange={(e) => onChange(e)}
          />
          <small
            id="createprofile-from-website-description"
            className="form-text"
          >
            Could be your own or a company website
          </small>
        </div>
        <div id="createprofile-from-location" className="form-group">
          <input
            id="createprofile-from-location-input"
            type="text"
            placeholder="Location"
            name="location"
            value={location}
            onChange={(e) => onChange(e)}
          />
          <small
            id="createprofile-from-location-description"
            className="form-text"
          >
            City & state suggested (eg. Boston, MA)
          </small>
        </div>
        <div id="createprofile-from-skills" className="form-group">
          <input
            id="createprofile-from-skills-input"
            type="text"
            placeholder="* Skills"
            name="skills"
            value={skills}
            onChange={(e) => onChange(e)}
          />
          <small
            id="createprofile-from-skilss-description"
            className="form-text"
          >
            Please use comma separated values (eg. HTML,CSS,JavaScript,PHP)
          </small>
        </div>
        <div id="createprofile-from-github" className="form-group">
          <input
            id="createprofile-from-github-input"
            type="text"
            placeholder="Github Username"
            name="githubusername"
            value={githubusername}
            onChange={(e) => onChange(e)}
          />
          <small
            id="createprofile-from-github-description"
            className="form-text"
          >
            If you want your latest repos and a Github link, include your
            username
          </small>
        </div>
        <div id="createprofile-from-bio" className="form-group">
          <textarea
            id="createprofile-from-bio-input"
            placeholder="A short bio of yourself"
            name="bio"
            value={bio}
            onChange={(e) => onChange(e)}
          />
          <small id="createprofile-from-bio-description" className="form-text">
            Tell us a little about yourself
          </small>
        </div>

        <div className="my-2" id="createprofile-from-addsocial-container">
          <div id="createprofile-from-addsocial-itemcontainer">
            <span> Add Social Network Links</span>{" "}
            <RadioButton
              label=" No"
              value={displaySocialInputs === false}
              onChange={handleRadio}
            />{" "}
            <RadioButton
              label=" Yes"
              value={displaySocialInputs === true}
              onChange={handleRadio}
            />{" "}
            <small
              id="createprofile-from-addsocial-optional-text"
              className="form-text"
            >
              *Optional
            </small>
          </div>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div
              className="form-group social-input"
              id="createprofile-from-addsocial-twitter"
            >
              <i className="fab fa-twitter fa-2x" data-for="twitter" data-tip />
              <ReactTooltip id="twitter" place="top" effect="solid">
                Add your twitter screen name without @
              </ReactTooltip>
              <input
                id="createprofile-from-addsocial-twitter-input"
                type="text"
                placeholder="Twitter screen name i.e: JavaScriptDaily----"
                name="twitter"
                value={twitter}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              className="form-group social-input"
              id="createprofile-from-addsocial-facebook"
            >
              <i
                className="fab fa-facebook fa-2x"
                id="createprofile-from-addsocial-facebook-icon"
              />

              <input
                id="createprofile-from-addsocial-facebook-input"
                type="text"
                placeholder="Facebook URL"
                name="facebook"
                value={facebook}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              id="createprofile-from-addsocial-youtube"
              className="form-group social-input"
            >
              <i
                className="fab fa-youtube fa-2x"
                id="createprofile-from-addsocial-youtube-icon"
              />
              <input
                id="createprofile-from-addsocial-youtube-input"
                type="text"
                placeholder="YouTube URL"
                name="youtube"
                value={youtube}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              id="createprofile-from-addsocial-linkedIn"
              className="form-group social-input"
            >
              <i
                className="fab fa-linkedin fa-2x"
                id="createprofile-from-addsocial-linkedin-icon"
              />
              <input
                id="createprofile-from-addsocial-linkedin-input"
                type="text"
                placeholder="Linkedin URL"
                name="linkedin"
                value={linkedin}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              className="form-group social-input"
              id="createprofile-from-addsocial-instagram"
            >
              <i
                className="fab fa-instagram fa-2x"
                id="createprofile-from-addsocial-instagram-icon"
              />
              <input
                id="createprofile-from-addsocial-instagram-input"
                type="text"
                placeholder="Instagram URL"
                name="instagram"
                value={instagram}
                onChange={(e) => onChange(e)}
              />
            </div>
          </Fragment>
        )}

        <input
          id="createprofile-from-addsocial-btn"
          type="submit"
          className="btn btn-primary my-1"
        />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
};

export default connect(null, { createProfile })(withRouter(CreateProfile));
