import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import githubLogo from "./github-logo.svg";

const domain =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:5001";

const Landing = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <section className="landing" id="landingpage">
      <div className="dark-overlay" id="landingpage-container">
        <div className="landing-inner" id="landingpage-innercontainer">
          <h1 className="x-large" id="landingpage-innercontainer-h1">
            DevBook
          </h1>
          <p className="lead" id="landingpage-innercontainer-p">
            Share posts, connect and get help from other developers with
            DevBook
          </p>
          <div className="buttons" id="landingpage-innercontainer-buttons">
            <Link to="/register" className="btn btn-primary">
              Sign Up
            </Link>
            <Link to="/login" className="btn btn-light">
              Login
            </Link>
            <h4 className="or" id="landingpage-innercontainer-h4">
              or sign in with social
            </h4>
            <div
              className="social-buttons"
              id="landingpage-innercontainer-social-google"
            >
              {/* Google sign in button */}
              <a
                id="landingpage-innercontainer-social-google-anchor"
                href={`${domain}/api/auth/social/google`}
                className="social-button google"
                // style={{ visibility: "hidden" }}
              >
                <div id="landingpage-innercontainer-social-google-container">
                  <span
                    className="svgIcon"
                    id="landingpage-innercontainer-social-google-span1"
                  >
                    <svg width="25" height="37" viewBox="0 0 25 25">
                      <g fill="none">
                        <path
                          d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.65 2.535-6.24z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.5 21c2.295 0 4.22-.76 5.625-2.06l-2.747-2.132c-.76.51-1.734.81-2.878.81-2.214 0-4.088-1.494-4.756-3.503h-2.84v2.202A8.498 8.498 0 0 0 12.5 21z"
                          fill="#34A853"
                        />
                        <path
                          d="M7.744 14.115c-.17-.51-.267-1.055-.267-1.615s.097-1.105.267-1.615V8.683h-2.84A8.488 8.488 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.79 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z"
                          fill="#EA4335"
                        />
                      </g>
                    </svg>
                  </span>
                  <span
                    className="button-label"
                    id="landingpage-innercontainer-social-google-span2"
                  >
                    Sign in with Google
                  </span>
                </div>
              </a>
              {/* Github sign in button */}
              <a
                id="landingpage-innercontainer-social-github-anchor"
                href={`${domain}/api/auth/social/github`}
                className="social-button github"
              >
                <div
                  className="github-button-content"
                  id="landingpage-innercontainer-social-github-container"
                >
                  <img
                    id="landingpage-innercontainer-social-github-image"
                    className="github-icon"
                    src={githubLogo}
                    height="20"
                    alt="github"
                  />
                  <span
                    className="sign-in-social-button"
                    id="landingpage-innercontainer-social-github-span1"
                  >
                    Sign in with Github
                  </span>
                  <span
                    className="button-label"
                    d="landingpage-innercontainer-social-github-span2"
                  />
                </div>
              </a>
              {/* Facebook sign in button */}
              <a
                id="landingpage-innercontainer-social-facebook-anchor"
                href={`${domain}/api/auth/social/facebook`}
                // className="social-button facebook"
                // style={{ visibility: "hidden" }}
                hidden
              >
                <div id="landingpage-innercontainer-social-facebook-container">
                  <span
                    className="svgIcon"
                    id="landingpage-innercontainer-social-facebook-span1"
                  >
                    <img
                      id="landingpage-innercontainer-social-facebook-image"
                      className="facebook-icon"
                      src={require("./facebook.svg")}
                      height="20"
                      alt=""
                    />
                    <span
                      className="sign-in-social-button"
                      id="landingpage-innercontainer-social-facebook-span2"
                    >
                      Sign in with Facebook
                    </span>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
