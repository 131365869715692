import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ProfileItem = ({
  profile: { user, status, company, location, skills },
}) => {
  const { id, name, avatar } = user || { id: "", name: "", avatar: "" };

  return (
    <div className="profile bg-light" id="profile-item-container">
      <img src={avatar} alt="" className="round-img" id="profile-item-image" />
      <div id="profile-item-detail">
        <h2 id="profile-item-detail-name">{name}</h2>
        <p id="profile-item-detail-status+company">
          {status} {company && <span> at {company}</span>}
        </p>
        <p id="profile-item-detail-location" className="my-1">
          {location && <span>{location}</span>}
        </p>
        {id ? (
          <Link to={`/profile/${id}`} className="btn btn-primary">
            View Profile
          </Link>
        ) : undefined}
      </div>
      <ul id="profile-item-detail-skills">
        {skills.slice(0, 4).map((skill, index) => (
          <li
            id="profile-item-datail-skills-item"
            key={index}
            className="text-primary"
          >
            <i id="profile-item-datail-skills-icon" className="fas fa-check" />{" "}
            {skill}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileItem;
