import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import ReactTooltip from "react-tooltip";
import RadioButton from "../layout/RadioButton";
import Slider from "../layout/Slider";

const EditProfile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const [formData, setFormData] = useState({
    company: "",
    year: "",
    website: "",
    location: "",
    status: "",
    skills: "",
    githubusername: "",
    bio: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const handleRadio = () => {
    toggleSocialInputs(!displaySocialInputs);
  };

  useEffect(() => {
    getCurrentProfile();

    setFormData({
      company: loading || !profile.company ? "" : profile.company,
      year: loading || !profile.year ? "" : profile.year,
      website: loading || !profile.website ? "" : profile.website,
      location: loading || !profile.location ? "" : profile.location,
      status: loading || !profile.status ? "" : profile.status,
      skills: loading || !profile.skills ? "" : profile.skills.join(","),
      githubusername:
        loading || !profile.githubusername ? "" : profile.githubusername,
      bio: loading || !profile.bio ? "" : profile.bio,
      twitter: loading || !profile.social ? "" : profile.social.twitter,
      facebook: loading || !profile.social ? "" : profile.social.facebook,
      linkedin: loading || !profile.social ? "" : profile.social.linkedin,
      youtube: loading || !profile.social ? "" : profile.social.youtube,
      instagram: loading || !profile.social ? "" : profile.social.instagram,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getCurrentProfile]);

  const {
    company,
    year,
    website,
    location,
    status,
    skills,
    githubusername,
    bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  return (
    <Fragment>
      <h1 className="large text-primary" id="editprofile-heading">
        Edit Your Profile
      </h1>
      <p className="lead" id="editprofile-intro-text">
        <i id="editprofile-intro-icon" className="fas fa-user" /> Let's get some
        information to make your profile stand out
      </p>
      <small>* = required field</small>
      <form
        id="editprofile-from"
        className="form"
        onSubmit={(e) => onSubmit(e)}
      >
        <div id="editprofile-from-status" className="form-group">
          <select
            id="editprofile-from-status-select"
            name="status"
            value={status}
            onChange={(e) => onChange(e)}
          >
            <option id="createprofile-from-status-option1" value="0">
              * Select Professional Status
            </option>
            <option id="createprofile-from-status-option2" value="QA Engineer">
              QA Engineer
            </option>
            <option
              id="createprofile-from-status-option3"
              value="Senior QA Engineer"
            >
              Senior QA Engineer
            </option>
            <option
              id="createprofile-from-status-option4"
              value="QA Manuel Tester"
            >
              QA Manuel Tester
            </option>
            <option
              id="createprofile-from-status-option5"
              value="QA Automation Tester"
            >
              QA Automation Tester
            </option>
            <option id="createprofile-from-status-option6" value="Developer">
              Developer
            </option>
            <option
              id="createprofile-from-status-option7"
              value="Junior Developer"
            >
              Junior Developer
            </option>
            <option
              id="createprofile-from-status-option8"
              value="Senior Developer"
            >
              Senior Developer
            </option>
            <option id="createprofile-from-status-option9" value="Manager">
              Manager
            </option>
            <option
              id="createprofile-from-status-option10"
              value="Student or Learning"
            >
              Student or Learning
            </option>
            <option id="createprofile-from-status-option11" value="Instructor">
              Instructor or Teacher
            </option>
            <option id="createprofile-from-status-option12" value="Intern">
              Intern
            </option>
            <option id="createprofile-from-status-option13" value="Other">
              Other
            </option>
          </select>
          <small id="editprofile-from-status-description" className="form-text">
            Give us an idea of where you are at in your career
          </small>
        </div>

        {/* Slider Area Start*/}
        <div id="editprofile-from-slider" className="slidecontainer">
          <Slider
            name="year"
            value={year}
            min={0}
            max={10}
            step={1}
            onChange={onChange}
          />
          <small
            id="createprofile-from-slider-description"
            className="form-text"
          >
            How many years of work experience you have?
          </small>
        </div>
        {/* Slider Area End*/}

        <div id="editprofile-from-company" className="form-group">
          <input
            id="editprofile-from-company-input"
            type="text"
            placeholder="Company"
            name="company"
            value={company}
            onChange={(e) => onChange(e)}
          />
          <small
            id="editprofile-from-company-description"
            className="form-text"
          >
            Could be your own company or one you work for
          </small>
        </div>
        <div id="editprofile-from-website" className="form-group">
          <input
            id="editprofile-from-website-input"
            type="text"
            placeholder="Website"
            name="website"
            value={website}
            onChange={(e) => onChange(e)}
          />
          <small
            id="editprofile-from-website-description"
            className="form-text"
          >
            Could be your own or a company website
          </small>
        </div>
        <div id="editprofile-from-location" className="form-group">
          <input
            id="editprofile-from-location-input"
            type="text"
            placeholder="Location"
            name="location"
            value={location}
            onChange={(e) => onChange(e)}
          />
          <small
            id="editprofile-from-location-description"
            className="form-text"
          >
            City & state suggested (eg. Boston, MA)
          </small>
        </div>
        <div id="editprofile-from-skills" className="form-group">
          <input
            id="ceditprofile-from-skills-input"
            type="text"
            placeholder="* Skills"
            name="skills"
            value={skills}
            onChange={(e) => onChange(e)}
          />
          <small id="editprofile-from-skilss-description" className="form-text">
            Please use comma separated values (eg. HTML,CSS,JavaScript,PHP)
          </small>
        </div>

        {/* Drag and Drop */}

        <div id="editprofile-from-github" className="form-group">
          <input
            id="editprofile-from-github-input"
            type="text"
            placeholder="Github Username"
            name="githubusername"
            value={githubusername}
            onChange={(e) => onChange(e)}
          />
          <small id="editprofile-from-github-description" className="form-text">
            If you want your latest repos and a Github link, include your
            username
          </small>
        </div>
        <div id="editprofile-from-bio" className="form-group">
          <textarea
            id="editprofile-from-bio-input"
            placeholder="A short bio of yourself"
            name="bio"
            value={bio}
            onChange={(e) => onChange(e)}
          />
          <small className="form-text">Tell us a little about yourself</small>
        </div>

        <div id="editprofile-from-addsocial-container" className="my-2">
          <div id="editprofile-from-addsocial-itemcontainer">
            <span> Add Social Network Links</span>{" "}
            <RadioButton
              label=" No"
              value={displaySocialInputs === false}
              onChange={handleRadio}
            />{" "}
            <RadioButton
              label=" Yes"
              value={displaySocialInputs === true}
              onChange={handleRadio}
            />{" "}
            <small
              id="editprofile-from-addsocial-optional-text"
              className="form-text"
            >
              *Optional
            </small>
          </div>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div
              className="form-group social-input"
              id="editprofile-from-addsocial-twitter"
            >
              <i className="fab fa-twitter fa-2x" data-for="twitter" data-tip />
              <input
                type="text"
                placeholder="Twitter screen name i.e: JavaScriptDaily"
                name="twitter"
                value={twitter}
                onChange={(e) => onChange(e)}
              />
              <ReactTooltip id="twitter" place="right" effect="solid">
                Add your twitter screen name without @
              </ReactTooltip>
            </div>

            <div
              id="editprofile-from-addsocial-facebook"
              className="form-group social-input"
            >
              <i
                id="editprofile-from-addsocial-facebook-icon"
                className="fab fa-facebook fa-2x"
              />
              <input
                id="editprofile-from-addsocial-facebook-input"
                type="text"
                placeholder="Facebook URL"
                name="facebook"
                value={facebook}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              id="editprofile-from-addsocial-youtube"
              className="form-group social-input"
            >
              <i
                id="editprofile-from-addsocial-youtube-icon"
                className="fab fa-youtube fa-2x"
              />
              <input
                id="editprofile-from-addsocial-youtube-input"
                type="text"
                placeholder="YouTube URL"
                name="youtube"
                value={youtube}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              id="editprofile-from-addsocial-linkedIn"
              className="form-group social-input"
            >
              <i
                id="editprofile-from-addsocial-linkedin-icon"
                className="fab fa-linkedin fa-2x"
              />
              <input
                id="editprofile-from-addsocial-linkedin-input"
                type="text"
                placeholder="Linkedin URL"
                name="linkedin"
                value={linkedin}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div
              id="editprofile-from-addsocial-instagram"
              className="form-group social-input"
            >
              <i
                id="editprofile-from-addsocial-instagram-icon"
                className="fab fa-instagram fa-2x"
              />
              <input
                id="editprofile-from-addsocial-instagram-input"
                type="text"
                placeholder="Instagram URL"
                name="instagram"
                value={instagram}
                onChange={(e) => onChange(e)}
              />
            </div>
          </Fragment>
        )}

        <input
          id="editprofile-from-addsocial-btn"
          type="submit"
          className="btn btn-primary my-1"
        />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(EditProfile)
);
