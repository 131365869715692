// import { useState, useEffect } from "react";

const Slider = ({
  onChange,
  value = 0,
  name = "year",
  min = 0,
  max = 100,
  step = 1,
}) => {
  let arr = Array.from(Array(max + 1).keys());

  return (
    <div style={{ display: "flex", flex: 1 }} id="slider-container">
      <input
        id="slider-input"
        name={name}
        style={{ flex: 9 }}
        onInput={onChange}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        list="slider-tick-list"
        // onChange={(e) => console.log(e.target)}
      />
      <datalist id="slider-tick-list">
        {arr.map((val, index) => (
          <option key={index}>{val}</option>
        ))}
      </datalist>
      <span
        style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
        id="slider-output"
      >
        {value}
      </span>
    </div>
  );
};

export default Slider;
